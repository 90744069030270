import React from 'react'
import { ReactElement } from 'react'
import PropTypes from 'prop-types'

type HTMLProps = {
  htmlAttributes: Record<string, unknown>
  headComponents: Array<any>
  bodyAttributes: Record<string, unknown>
  preBodyComponents: Array<any>
  body: string
  postBodyComponents: Array<any>
}

export default function HTML(props: HTMLProps): ReactElement {
  return (
    <html {...props.htmlAttributes}>
      <head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {props.headComponents}
        <noscript id="gatsby-noscript">
          This page works best with JavaScript enabled.
        </noscript>
      </head>
      <body {...props.bodyAttributes}>
        {props.preBodyComponents}
        <div
          key={`body`}
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: props.body }}
        />
        {props.postBodyComponents}
      </body>
    </html>
  )
}

HTML.propTypes = {
  htmlAttributes: PropTypes.object,
  headComponents: PropTypes.array,
  bodyAttributes: PropTypes.object,
  preBodyComponents: PropTypes.array,
  body: PropTypes.string,
  postBodyComponents: PropTypes.array,
}
